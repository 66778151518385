
    import { Component, Prop, Vue, Watch } from "vue-property-decorator";
    import { IReportData, IReportRate, IReportUser } from "@/types/report";
    import { IProject, ITaskTime, ITeam } from "@/types/api";
    import { DurationHelper } from "@/services/DurationHelper";
    import { NO_PROJECT } from "@/config";

    const SELECT_ALL_OPTION: IProject = { id: -1, name: 'Select All', hasExternalId: false, isNonBillable: false, timesheetColor: "" }

    @Component({})
    export default class Report extends Vue {
        private startDate: Date = new Date();
        private endDate: Date = new Date();
        private projectsList: IProject[] = [];
        private loadingProjectsList: boolean = false;
        private tasksList: ITaskTime[] = [];
        private usersList: IReportUser[] = [];
        private selectedUsers: IReportUser[] = [];
        private reportId: string = '';
        private reportSheetUrl: string = '';
        private reportSheetMessage: string = '';
        private reportGenerate: any;
        private userListFlag: boolean = true;
        private startReportFlag: boolean = false;
        private selectedTasks: ITaskTime[] = [];
        private isAllProjectsSelected: boolean = false;
        private getReportTimeout!: number;

        private billableSumText: string = '';
        private nonBillableSumText: string = '';

        // noinspection JSMismatchedCollectionQueryUpdate
        private reportData: IReportData[] = [];
        private sortUserByName: boolean = true
        // noinspection JSMismatchedCollectionQueryUpdate
        private selectedProjects: IProject[] = [];
        private team: ITeam[] = [];
        private teamId: number = 2187738;
        private rates: IReportRate[] = [];        
        
        get currentUser() {
          return this.$store.state.currentUser
        }

        created() {        
          if (this.currentUser && !this.currentUser.hasFullAccess) {
            this.$router.push({ path: '/' })
          }
        }

        @Watch('selectedProjects')
        async getUsersList(): Promise<void> {
            this.selectedUsers = [];
            this.reportData = [];
            this.reportId = '';
            this.reportSheetUrl = '';
            this.startReportFlag = false;

            if (!this.selectedProjects.length) {
                return;
            }

            const isAllSelected = this.selectedProjects.some((project) => project.id === SELECT_ALL_OPTION.id)

            if (isAllSelected) {
              this.selectedTasks = [...this.tasksList]
            } else {
              this.selectedTasks = this.tasksList.filter(item => this.selectedProjects.some(project => project.id == item.task.project.id));
            }

            let users: number[] = this.selectedTasks.reduce((all, element) => {
                if (all.every(item => item !== element.user.id)) {
                    all.push(element.user.id)
                }
                return all
            }, [] as number[]);

            let selectedProjects = this.selectedTasks.reduce((all, element) => {
                if (all.every(item => item != element.task.project.id)) {
                    all.push(element.task.project.id);
                }

                return all;
            }, [] as number[])

            this.selectedUsers = this.usersList
                .filter(item => users.some(user => user == item.id))
                .map(user => {
                    let rate = this.rates
                        .filter(item => selectedProjects.some(p => item.projectId === p))
                        .find(item => item.userId === user.id && item.value !== 0);
                    user.rate = rate ? rate.value : 0;
                    return user;
                });

            await this.generateReport();
        }

        @Watch('startReportFlag')
        clearReportLoading(){
            clearInterval(this.getReportTimeout)
        }

        get sortedProjectsList(): IProject[] {
            const _projectsList = this.projectsList.sort((first, second) => {
                const firstName = first.name.toUpperCase();
                const secondName = second.name.toUpperCase();

                let comparison = 0;
                if (firstName > secondName) {
                    comparison = 1;
                } else if (firstName < secondName) {
                    comparison = -1;
                }

                return comparison;
            })

            _projectsList.forEach((project) => {
                if (!project.hasExternalId)                    
                    project.name = project.name + " " + NO_PROJECT;
            })

          return [SELECT_ALL_OPTION, ..._projectsList];
        }

        get sortedReportData(): IReportData[] {
            return this.sortUserByName
                ? this.reportData.sort((first, second) => first.user.username.localeCompare(second.user.username))
                : this.reportData.sort((first, second) => second.user.username.localeCompare(first.user.username))
        }

        sumCost(): number {
            let sumCost = 0;
            this.reportData.forEach((item) => {
                sumCost += Number(item.cost.toFixed(2));
            });
            return Number(sumCost.toFixed(2));
        }

        async calculateSumBillableTime() {
            let sumBillable = 0;
            let sumNonBillable = 0;
            this.reportData.forEach((item) => {
                sumBillable += DurationHelper.timeToMilliseconds(item.billableText);
                sumNonBillable += DurationHelper.timeToMilliseconds(item.nonBillableText);
            })

            this.billableSumText = DurationHelper.millisecondsToString(sumBillable);
            this.nonBillableSumText = DurationHelper.millisecondsToString(sumNonBillable);

        }

        async getProjectsList(): Promise<void> {
            this.loadingProjectsList = true
            this.projectsList = [];
            this.tasksList = [];
            this.usersList = [];
            this.selectedUsers = [];
            this.selectedProjects = [];
            this.reportData = [];
            this.reportId = '';
            this.reportSheetUrl = '';
            this.startReportFlag = false;

            this.rates = await this.$store.dispatch("GetRates");
            this.team = await this.$store.dispatch("GetTeam", {removeLoaderFlag: false});
            const timesResponse = await this.$store.dispatch("GetReportTimes", { startDate: this.startDate, endDate: this.endDate }); // await reportService.getReportTimes(this.startDate, this.endDate);
            this.projectsList = timesResponse.projects;
            this.tasksList = timesResponse.times;

            this.usersList = this.createUsersList();
            this.loadingProjectsList = false
        }

        createUsersList(): IReportUser[] {
            return this.tasksList.reduce((all: IReportUser[], element: ITaskTime) => {
                if (!all.some(item => item.id == element.user.id)) {
                    let user = this.team.find(item => item.id === element.user.id);
                    if(user)
                        all.push({
                            id: user.id,
                            username: user.name,
                            position: user.position,
                            rate: 0
                        });
                }

                return all;
            }, [] as IReportUser[]);
        }

        async generateReport(): Promise<void> {
            const report = await this.$store.dispatch("GenerateReport", { selectedTasks: this.selectedTasks, selectedUsers: this.selectedUsers })

            this.reportData = report
                .sort(function (a, b) {
                    if (a.taskName < b.taskName) {
                        return -1;
                    }
                    if (a.taskName > b.taskName) {
                        return 1;
                    }
                    return 0;
                });

            this.calculateSumBillableTime();
        }

        async generateGoogleSheetReport(): Promise<void> {
            this.startReportFlag = true;
            this.rates = this.reportData.reduce((all, element) => {
                let item = all.find(item => item.userId == element.user.id && item.projectId == element.projectId);
                if (item) {
                    all = all.filter(i => i != item)
                }
                
                all.push({
                    projectId: element.projectId,
                    userId: element.user.id,
                    value: element.user.rate
                })

                return all;
            }, this.rates);

            await this.$store.dispatch("SaveRates", { reportData: this.reportData })

            let sheetName = this.selectedProjects.map(z => z.name).join(",");
            this.reportId = await this.$store.dispatch("SaveToGoogleSheets", {
                reportData: this.reportData,
                sheetName,
                startDate: this.startDate,
                endDate: this.endDate,
                rateMembers: this.rates,
            })

            this.getReportTimeout = await setInterval(async () => {
                this.reportGenerate = await this.$store.dispatch("GetReport", { reportData: this.reportId });
                this.reportSheetUrl = this.reportGenerate.googleSheetUrl;
                this.reportSheetMessage = this.reportGenerate.message;
                if (this.reportSheetUrl !== "") {
                    clearInterval(this.getReportTimeout)

                    if (this.reportSheetUrl !== "-1") {
                        // let reportWindow = window.open(this.reportSheetUrl, '_blank');
                        // if (reportWindow) {
                        //     reportWindow.focus();
                        // }
                    } else {
                        this.startReportFlag = false;
                        this.reportSheetUrl = "";
                    }
                }
            }, 10 * 1000)
        }

        loadFile() {
            this.startReportFlag = false;
            this.reportSheetUrl = ''
        }

        getProjectName(id: string) {
          if (!this.projectsList) return;
          const project = this.projectsList.filter((project) => `${project.id}` === `${id}`)[0]
          return project ? project.name : ''
        }

        handleSelectingProjects(option: IProject) {
          this.isAllProjectsSelected = option.id === SELECT_ALL_OPTION.id
        }

        handleSelectProjects(options: IProject[]) {
          if (this.isAllProjectsSelected) {
            this.selectedProjects = [SELECT_ALL_OPTION]
          } else {
            this.selectedProjects = options.filter((project) => project.id !== SELECT_ALL_OPTION.id)
          }
        }
    }
